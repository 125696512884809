import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import SimplePortableText from '../components/sanity/simplePortableText';

const ExploreSlider = loadable(() =>
  import('../components/pageModules/modules/exploreSlider'),
);

const SimplePageTemplate = ({ data: { page } }) => {
  const { seo, title, text } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      <div className='relative z-10 grid grid-cols-14 pt-36 lg:pt-64'>
        <div className='col-start-2 col-end-14 lg:col-start-3 lg:col-end-13'>
          <h1 className='blockH1 pb-12 lg:pb-28 text-teal'>{title}</h1>
          {text && <SimplePortableText text={text} />}
        </div>
      </div>
      {/* Module at bottom of page */}
      <ExploreSlider className='pt-space-m pb-space-m' />
    </Layout>
  );
};

export default SimplePageTemplate;

export const simpePageQuery = graphql`
  query standardSimplePageQuery($slug: String!) {
    page: sanitySimplePage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      text: _rawText(resolveReferences: { maxDepth: 10 })
      title
    }
  }
`;
